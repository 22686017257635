/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useFrame, useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { Mesh } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { CameraControls, useGLTF } from "@react-three/drei";

const deg2Rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

interface IChickenProps {
  width: number;
}

export const Chicken = ({ width }: IChickenProps) => {
  const cameraPos = width < 800 ? 5 : 3;
  const { camera } = useThree();
  const { scene } = useGLTF("/models/chicken.glb");

  camera.position.set(0, 0, cameraPos); // Set position like this
  camera.rotateX(deg2Rad(180)); // Rotate like this
  camera.lookAt(new THREE.Vector3(0, 0, 0)); // Set look at coordinate like this
  camera.layers.enable(1);

  const chickenRef = useRef<THREE.Group<THREE.Object3DEventMap>>(null!);
  useEffect(() => {
    camera.layers.enable(0);
    if (chickenRef.current) {
      chickenRef.current.rotation.set(0, 0, 0);
    }
  }, []);

  // every frame, move the sun around the cloud
  useFrame((state, delta) => {
    const t = state.clock.getElapsedTime();
    const rotateY = Math.sin((t / Math.PI) * 2) * Math.PI;
    if (!chickenRef.current) return;

    chickenRef.current.rotation.y = -t * 1.5;
  });

  scene.setRotationFromEuler(new THREE.Euler(Math.PI / 2, 0, 0));
  scene.scale.set(10, 10, 10);
  scene.position.set(0, -2, 0);

  return (
    <group dispose={null}>
      <CameraControls minPolarAngle={0} maxPolarAngle={Math.PI / 1.6} />

      <group ref={chickenRef} position={[0, 0, -2]}>
        <primitive object={scene} />
      </group>
    </group>
  );
};
